import React from 'react'

import { Link, useStaticQuery, graphql } from 'gatsby'

import LogoNf from '../../images/icon-logo.svg'
import BackgroundImage from 'gatsby-background-image'

function SectionDemonstracao() {
  const images = useStaticQuery(graphql`
    query {
      BackgroundPessoas: file(relativePath: { eq: "banners/banner-demo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag="section"
      className="s-demo"
      fluid={images.BackgroundPessoas.childImageSharp.fluid}
      backgroundColor={`transparent`}
    >
      <div className="container">
        <div className="box">
          <div className="logo">
            <img src={LogoNf} alt="Icone Inovafarma" />
          </div>
          <p>Podemos ajudar a melhorar seus resultados</p>
          <h2>Solicite uma demonstração agora mesmo</h2>
          <Link to="/sobre/contato" className="btn">
            Conversar com um especialista
          </Link>
          <span>Entraremos em contato em até 1 dia útil</span>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default SectionDemonstracao
